#root, .app, .ant-layout {
  height: 100%;
  background: white;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #fff !important;
  padding: 15px !important;
}

#sub-header {
  display: flex;
  align-items: center;
}

#sub-header h2 {
  margin-right: 15px;
}

#sub-content {

}

.non-colored-link {
  color: rgba(0,0,0,.85);
}

/* ASSET MANAGEMENT -> AREA */
.area-item {
  background-color: white;
  padding: 12px 0 12px 40px;
  color: rgba(0,0,0,.85);
}

/* MEASUREMENTS */
.measurements-overview-asset {
  margin: 15px;
}

.measurements-widget {
  margin: 5px;
}

.space-algn-center {
  display: flex !important;
  align-items: center !important;
}

/* TICKETS */

.ticket-list .ant-list-item{
  border-bottom: 0 !important;
  padding: 0 0 0 0 !important;
  margin: 0 0 0 10px !important;
  display: block !important;
}

.ant-list {
  border-radius: 8px !important;
}

.ant-layout-sider-children {
  background-color: white !important;
}

.tickets-details-card {
  /* max-height: 40vh; */
  /* overflow-y: scroll; */
  margin: 0px 16px 16px 0;
}

.open-services-list .ant-list-header  {
  background-color: #F9BE76 !important;
  border-radius: 8px 8px 0 0 !important;
}

.open-services-list .ant-spin-container {
  background-color: #FDE3C4 !important;
  border-radius: 0 0 8px 8px !important;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.tickets-modal-create {
  width: 70vw !important;
}

.tickets-kanban {
  display: flex;
}

.border-radius-setting {
  border-radius: 0 8px 8px 8px !important

}

.rc-virtual-list-holder-inner {
  background-color: #f5f5f5 !important;
  border-radius: 0 8px 8px 8px !important;
}

.tickets-status-columns-header .ant-space-item {
  background-color: #f5f5f5 !important;
  border-radius: 8px 8px 0 0 !important;
  padding: 0 10px 0 10px !important;
}

.tickets-kanban-card {
  margin: 15px 15px 15px 0;
}

.ant-list-item-meta-title {
  margin-top: 0px !important;
}

.tickets-kanban-card-name {
}

.tickets-kanban-card-divider {
  
}

.ant-timeline-item-head {
  background-color: #f5f5f5 !important;
}





